import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../../axiosInstance";
import API from "../../api";
import Filter from "./Filter";
import { AppContext } from "../../Context/AppContext";

import { CircularProgress, Button, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import { useNavigate } from "react-router-dom";
import RemoveDialog from "../../Components/RemoveDialog";
import DateTimeBody from "../../Components/Columns/DateTimeBody";
import StateBody from "../../Components/Columns/StateBody";
import LinkBody from "../../Components/Columns/LinkBody";

function Orders({ permissions }) {
  const navigate = useNavigate();
  const { setShowBackButton } = useContext(AppContext);
  const [DataTable, setDataTable] = useState([]);
  const [filterValues, setFilterValues] = useState({
    carIds: [],
    paymentStatus: [],
    createdBy: undefined,
    from: undefined,
    to: undefined,
  });
  const [OpenRemove, setOpenRemove] = useState(false);
  const [RowData, setRowData] = useState({});
  const [Refresh, setRefresh] = useState(false);
  const [pageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [CarIDsOptions, setCarIDsOptions] = useState([]);
  const [Creators, setCreators] = useState([]);

  useEffect(() => {
    setShowBackButton(false);
  }, []);
  useEffect(() => {
    // get all without filter
    axiosInstance.get(API.orders).then((res) => {
      let allCars = [];
      let creatorsId = [];
      let creators = [];
      res?.data?.data?.forEach((ele) => {
        ele?.cars?.forEach((c) => {
          allCars = [...allCars, c?.carId?.carId];
        });
        if (!creatorsId?.includes(ele?.createdBy?._id)) {
          creatorsId = [...creatorsId, ele?.createdBy?._id];
          creators = [...creators, ele?.createdBy];
        }
      });
      setCarIDsOptions(
        [...new Set(allCars)]?.filter(
          (ele) => ele !== undefined && ele !== null
        )
      );
      setCreators(creators);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(API.orders, {
        params: {
          carIds: filterValues?.carIds?.join(","),
          // paymentStatus: filterValues?.paymentStatus?.value,
          paymentStatus: filterValues?.paymentStatus
            ?.map((ele) => ele?.value)
            ?.join(","),
          createdBy: filterValues?.createdBy?._id || undefined,
          pickupDate: filterValues?.from
            ? new Date().toISOString(filterValues?.from).split("T")[0]
            : undefined,
          pickupTime: filterValues?.from
            ? new Date()
                .toISOString(filterValues?.from)
                .split("T")[1]
                .split(".")[0]
            : undefined,
          returnDate: filterValues?.to
            ? new Date().toISOString(filterValues?.to).split("T")[0]
            : undefined,
          returnTime: filterValues?.to
            ? new Date()
                .toISOString(filterValues?.to)
                .split("T")[1]
                .split(".")[0]
            : undefined,
        },
      })
      .then((res) => {
        setTotalRecords(res?.data?.count);
        let data = res?.data?.data?.map((val, i) => ({
          ...val,
          // orderNumber: i + 1,
          id: val?._id,
          customer_name: val?.customer?.name || "",
          createdBy_name: val?.createdBy?.name || "",
        }));
        setPage(0);
        setDataTable([...data]);
        setLoading(false);
      })
      .catch((err) => {
        setTotalRecords(0);
        setDataTable([]);
        setLoading(false);
      });
  }, [filterValues, Refresh]);
  const colors = {
    in_route: "#D80027",
    pending: "#FF9407",
    completed: "#0F930F",
    paid: "#0F930F",
    partially_paid: "#FF9407",
    unpaid: "#D80027",
  };
  const columns = [
    {
      field: "orderNumber",
      headerName: "No",
      align: "left",
      headerAlign: "left",
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      editable: true,
    },
    {
      field: "createdBy_name",
      headerName: "User",
      align: "left",
      headerAlign: "left",
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      editable: true,
    },
    {
      field: "carId",
      headerName: "Car number",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: true,
      disableColumnMenu: true,
      sortable: false,
      editable: true,
      renderCell: (params) => (
        <Stack
          direction={"column"}
          justifyContent={"center"}
          sx={{ height: "100%" }}
        >
          {params?.row?.cars
            // ?.filter((car) => car?.carId?.carId)
            .map((car) => {
              if (!car?.carId?.carId) {
                return (
                  <Typography variant="subtitle2">
                    {car?.carId?.carId || "__"}
                  </Typography>
                );
              } else {
                return (
                  <LinkBody
                    value={car?.carId?.carId || "__"}
                    to={`/vehicles/details/${car?.carId?._id}`}
                  />
                );
              }
            })}
        </Stack>
      ),
    },
    {
      field: "customer_name",
      headerName: "Customer",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      editable: true,
      renderCell: (params) => (
        <LinkBody
          value={params?.row?.customer_name}
          to={`/customers/details/${params?.row?.customer?._id}`}
        />
      ),
    },
    {
      field: "Pick-Up date",
      headerName: "Pick-Up date",
      align: "left",
      headerAlign: "left",
      flex: 2,
      hideSortIcons: true,
      disableColumnMenu: true,
      sortable: false,
      editable: true,
      renderCell: (params) => (
        <Stack
          direction={"column"}
          justifyContent={"center"}
          sx={{ height: "100%" }}
        >
          {params?.row?.cars?.map((car) => (
            <DateTimeBody
              value={car?.pickupDateTime}
              color="#201D23CC"
              hideIcon
            />
          ))}
        </Stack>
      ),
    },
    {
      field: "Return date",
      headerName: "Return date",
      align: "left",
      headerAlign: "left",
      flex: 2,
      hideSortIcons: true,
      disableColumnMenu: true,
      sortable: false,
      editable: true,
      renderCell: (params) => (
        <Stack
          direction={"column"}
          justifyContent={"center"}
          sx={{ height: "100%" }}
        >
          {params?.row?.cars?.map((car) => (
            <DateTimeBody
              value={car?.returnDateTime}
              color="#201D23CC"
              hideIcon
            />
          ))}
        </Stack>
      ),
    },
    {
      field: "createdAt",
      headerName: "Order date",
      align: "left",
      headerAlign: "left",
      flex: 2,
      hideSortIcons: true,
      disableColumnMenu: true,
      sortable: false,
      editable: true,
      renderCell: (params) => (
        <Stack
          direction={"column"}
          justifyContent={"center"}
          sx={{ height: "100%" }}
        >
          <DateTimeBody
            value={params?.row?.updatedAt}
            color="#201D23CC"
            hideIcon
          />
        </Stack>
      ),
    },
    {
      field: "paymentStatus",
      headerName: "Status",
      align: "center",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: true,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <>
          {params?.row?.paymentStatus && (
            <StateBody
              color={colors[params?.row?.paymentStatus?.toLowerCase()]}
              value={params?.row?.paymentStatus}
            />
          )}
        </>
      ),
    },
    {
      field: "",
      headerName: "Actions",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableExport: true,
      sortable: false,
      renderCell: (params) => (
        <>
          {!permissions?.orders?.view ? (
            <small>Not authorized</small>
          ) : (
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: "none" }}
              onClick={() => navigate(`/orders/view/${params?.row?._id}`)}
            >
              View
            </Button>
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Filter
            setFilterValues={setFilterValues}
            filterValues={filterValues}
            CarIDsOptions={CarIDsOptions}
            Creators={Creators}
            showCreateBtn={permissions?.orders?.create}
            showBatch={permissions?.payment?.create}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          {Loading ? (
            <CircularProgress />
          ) : (
            <DataGrid
              rows={DataTable || []}
              columns={columns}
              style={{ overflow: "auto" }}
              pagination
              pageSize={pageSize}
              rowLength={totalRecords}
              pageSizeOptions={[5, 10, 25]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: pageSize, page: page },
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even"
              }
              sx={{
                borderColor: "#fff",
                color: "#201D23CC",
                lineHeight: "19.6px",
                fontSize: "14px",
                fontFamily: "Montserrat",
                "& .even": {
                  backgroundColor: "#FAFAFA !important",
                },
                "& .odd": {
                  backgroundColor: "#FFF !important",
                },
                "& .MuiDataGrid-row": {
                  maxHeight: "unset !important",
                },
                "& .MuiDataGrid-cell": {
                  border: "unset",
                  fontFamily: "Montserrat",
                  height: "unset !important",
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#FAFAFA !important",
                  borderBottom: "unset !important",
                },
                "& .MuiTablePagination-spacer": {
                  flex: "0 !important",
                },
                "& .MuiInputBase-root": {
                  marginRight: "auto !important",
                },
              }}
              disableRowSelectionOnClick
            />
          )}
        </Grid>
      </Grid>
      <RemoveDialog
        open={OpenRemove}
        setOpen={setOpenRemove}
        endpoint={API.customers}
        itemId={RowData?._id}
        setRefresh={setRefresh}
        Refresh={Refresh}
      />
    </>
  );
}
export default Orders;
