import React from "react";
import {
  Stack,
  Typography,
  Button,
  InputAdornment,
  Card,
  Autocomplete,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

function Filter({
  title,
  setFilterValues,
  filterValues,
  showCreateBtn,
  onClickAdd,
  FilterData,
}) {
  const navigate = useNavigate();
  // console.log("filterValues ", filterValues);
  return (
    <Card sx={{ padding: "32px 28px 40px 28px" }}>
      <Grid container spacing={1}>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Typography variant="h6" textAlign={"left"}>
            {title ? title : "Payments"}
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Stack
            direction={{ xs: "column", md: "column", lg: "row" }}
            spacing={2}
          >
            <Autocomplete
              sx={{ m: 1, width: "25ch" }}
              //size="small"
              freeSolo
              disableClearable
              options={FilterData?.payments}
              value={filterValues?.paymentNumber}
              onInputChange={(event, newInputValue) => {
                setFilterValues((prev) => ({
                  ...prev,
                  paymentNumber: newInputValue,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by payment"
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      type: "search",
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              )}
            />

            <Autocomplete
              sx={{ m: 1, width: "25ch" }}
              //size="small"
              freeSolo
              disableClearable
              options={FilterData?.orders}
              value={filterValues?.orderNumber}
              onInputChange={(event, newInputValue) => {
                setFilterValues((prev) => ({
                  ...prev,
                  orderNumber: newInputValue,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by order"
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      type: "search",
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              )}
            />
            <Autocomplete
              sx={{ m: 1, width: "25ch" }}
              //size="small"
              freeSolo
              disableClearable
              options={FilterData?.customers}
              getOptionLabel={(option) => option?.name}
              value={filterValues?.customerId}
              onChange={(event, newInputValue) => {
                setFilterValues((prev) => ({
                  ...prev,
                  customerId: newInputValue,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by customer"
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      type: "search",
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              )}
            />
            {showCreateBtn && (
              <Button
                variant="contained"
                onClick={() => {
                  if (typeof onClickAdd === "function") {
                    onClickAdd();
                  } else {
                    navigate("/customers/add");
                  }
                }}
                // loading={loading}
                color="secondary"
                //size="small"
                sx={{ textTransform: "none", marginLeft: "auto !important" }}
              >
                Add Customer
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}

export default Filter;
