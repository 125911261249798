import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../../axiosInstance";
import API from "../../api";
import { AppContext } from "../../Context/AppContext";
import {
  CircularProgress,
  Stack,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import { useNavigate } from "react-router-dom";
import { addCommas } from "../../lib";
import LinkBody from "../../Components/Columns/LinkBody";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

function BatchPayments({ permissions }) {
  const navigate = useNavigate();
  const { setShowBackButton } = useContext(AppContext);
  const [DataTable, setDataTable] = useState([]);
  const [Refresh, setRefresh] = useState(false);
  const [pageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [Error, setError] = useState({
    isError: false,
    errorMessage: "",
    errors: "",
  });

  useEffect(() => {
    setShowBackButton(false);
  }, []);
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(API.orders, {
        params: {
          paymentStatus: "Unpaid,partially_paid",
        },
      })
      .then((res) => {
        // let _data = res?.data?.data?.filter(
        //   (ele) => ele?.paymentStatus?.toLowerCase() !== "paid"
        // );
        let _data = res?.data?.data;
        setTotalRecords(_data?.length);
        let data = _data?.map((val, index) => ({
          ...val,
          customer_name: val?.customer?.name || "",
          paymentDate: dayjs(),
          paymentModeOptions: val?.payment?.method || [],
          transactionId: "",
          paymentMode: "",
          amountReceived: "",
          invoiceBalanceDue: "", // totalPrice - all received amount
          id: val?._id,
          index: index,
        }));
        setPage(0);
        setDataTable([...data]);
        setLoading(false);
      })
      .catch((err) => {
        setTotalRecords(0);
        setDataTable([]);
        setLoading(false);
      });
  }, [Refresh]);

  const onChangeRowFields = (index, name, value) => {
    let data = [...DataTable];
    data[index][name] = value;
    setDataTable(data);
  };
  const columns = [
    {
      field: "orderNumber",
      headerName: "Order No",
      align: "left",
      headerAlign: "left",
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params) => (
        <LinkBody
          value={params?.row?.orderNumber}
          to={`/orders/view/${params?.row?._id}`}
        />
      ),
    },
    {
      field: "customer_name",
      headerName: "Customer",
      align: "left",
      headerAlign: "left",
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      editable: true,
      renderCell: (params) => (
        <LinkBody
          value={params?.row?.customer_name}
          to={`/customers/details/${params?.row?.customer?._id}`}
        />
      ),
    },
    {
      field: "paymentDate",
      headerName: "Payment Date",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      editable: true,
      renderCell: (params) => (
        <LocalizationProvider
          sx={{ "& .MuiTextField-root": { width: "100% !important" } }}
          dateAdapter={AdapterDayjs}
        >
          <DatePicker
            sx={{ "& input": { padding: "8.5px 14px" } }}
            size="small"
            value={params?.row?.paymentDate}
            onChange={(newValue) => {
              onChangeRowFields(params?.row?.index, "paymentDate", newValue);
            }}
          />
        </LocalizationProvider>
      ),
    },
    {
      field: "paymentMode",
      headerName: "Payment Mode",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      editable: true,
      renderCell: (params) => (
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          spacing={1}
          sx={{ height: "100%", color: "#000" }}
        >
          <Select
            value={params?.row?.paymentMode || ""}
            onChange={(event) => {
              onChangeRowFields(
                params?.row?.index,
                "paymentMode",
                event.target.value
              );
            }}
            name={"paymentMode"}
            fullWidth
            sx={{ "& .MuiSelect-select": { width: "100%" } }}
            size="small"
            placeholder="Select payment method"
          >
            {params?.row?.paymentModeOptions?.map((ele) => (
              <MenuItem key={ele} value={ele}>
                {ele}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      ),
    },
    {
      field: "transactionId",
      headerName: "Transaction Id",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      editable: false,
      renderCell: (params) => (
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          spacing={1}
          sx={{ height: "100%", color: "#000" }}
        >
          <TextField
            size="small"
            fullWidth
            id={`transactionId-${params?.row?.index}`}
            value={params?.row?.transactionId}
            name="transactionId"
            onChange={(event) => {
              onChangeRowFields(
                params?.row?.index,
                "transactionId",
                event.target.value
              );
            }}
          />
        </Stack>
      ),
    },
    {
      field: "amountReceived",
      headerName: "Amount Received",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params) => (
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          spacing={1}
          sx={{ height: "100%", color: "#000" }}
        >
          <TextField
            size="small"
            type="number"
            fullWidth
            value={params?.row?.amountReceived}
            onChange={(event) => {
              if (
                event.target.value <= params?.row?.remainingAmount &&
                event.target.value >= 0
              ) {
                onChangeRowFields(
                  params?.row?.index,
                  "amountReceived",
                  event.target.value
                );
              }
            }}
          />
        </Stack>
      ),
    },
    {
      field: "remainingAmount",
      headerName: "Invoice Balance Due",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params) => (
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          spacing={1}
          sx={{ height: "100%", color: "#000" }}
        >
          <Typography variant="subtitle2">
            ${addCommas(params?.row?.remainingAmount)}
          </Typography>
        </Stack>
      ),
    },
  ];
  function handleResponse(res) {
    // console.log("res ", res);
    if (
      res?.data?.success === true ||
      res?.data?.order?._id ||
      res?.data?.data?._id ||
      res?.data?.payments
    ) {
      setLoadingBtn(false);
      navigate("/payments");
    } else {
      setLoadingBtn(false);
      setError({
        isError: true,
        errorMessage: res?.data?.error || "Some thing went wrong.",
        errors: res?.data?.errors?.join(", "),
      });
    }
  }
  const handleSavePayments = () => {
    const requestBody = {
      payments: DataTable?.filter(
        (ele) => ele.amountReceived && ele.paymentMode
      )?.map((ele) => ({
        orderNumber: ele?.orderNumber,
        paymentDate: ele?.paymentDate,
        paymentMode: ele?.paymentMode,
        transactionId: ele?.transactionId,
        amountReceived: ele?.amountReceived,
      })),
    };
    setLoadingBtn(true);
    setError({
      isError: false,
      errorMessage: "",
      errors: "",
    });
    axiosInstance.post("/payments/create", requestBody).then((res) => {
      handleResponse(res);
    });
  };
  console.log("Data ", DataTable);
  return (
    <>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          {Loading ? (
            <CircularProgress />
          ) : (
            <DataGrid
              rows={DataTable || []}
              columns={columns}
              style={{ overflow: "auto" }}
              pagination
              pageSize={pageSize}
              rowLength={totalRecords}
              pageSizeOptions={[5, 10, 25]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: pageSize, page: page },
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even"
              }
              sx={{
                borderColor: "#fff",
                color: "#201D23CC",
                lineHeight: "19.6px",
                fontSize: "14px",
                fontFamily: "Montserrat",
                "& .even": {
                  backgroundColor: "#FAFAFA !important",
                },
                "& .odd": {
                  backgroundColor: "#FFF !important",
                },
                "& .MuiDataGrid-cell": {
                  border: "unset",
                  fontFamily: "Montserrat",
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#FAFAFA !important",
                  borderBottom: "unset !important",
                },
                "& .MuiTablePagination-spacer": {
                  flex: "0 !important",
                },
                "& .MuiInputBase-root": {
                  marginRight: "auto !important",
                },
              }}
              disableRowSelectionOnClick
            />
          )}
        </Grid>
        {!Loading && (
          <Grid size={{ xs: 12, sm: 12, md: 12 }}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Button
                variant="outlined"
                onClick={handleSavePayments}
                color="secondary"
                size="small"
                disabled={LoadingBtn}
                sx={{ width: "140px", padding: "7px", textTransform: "none" }}
              >
                {LoadingBtn && (
                  <CircularProgress
                    sx={{
                      width: "20px !important",
                      height: "20px !important",
                      marginRight: "20px",
                    }}
                  />
                )}
                Apply
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
      <Snackbar
        open={Error.isError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() =>
            setError({
              isError: false,
              errorMessage: "",
              errors: "",
            })
          }
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {Error.errorMessage}
          {Error?.errors && <br />}
          {Error?.errors || ""}
        </Alert>
      </Snackbar>
    </>
  );
}
export default BatchPayments;
