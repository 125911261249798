import React, { useContext, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  Radio,
  Button,
  Card,
  MenuItem,
  Divider,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { AppContext } from "../../Context/AppContext";
import AppBar from "../../Components/AppBar";

function Terms() {
  const { setShowBackButton } = useContext(AppContext);
  const accessToken = localStorage.getItem("accessToken");
  useEffect(() => {
    setShowBackButton(true);
  }, []);
  return accessToken ? (
    <Card sx={{ padding: "15px" }}>Terms</Card>
  ) : (
    <AppBar>
      <Card sx={{ padding: "15px" }}>Terms</Card>
    </AppBar>
  );
}

export default Terms;
